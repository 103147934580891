<template>
    <div class="funnel">
        <template v-if="error">
            <p class="error-message">
                {{ error }}
            </p>
        </template>
        <template v-else>
            <div
                v-if="size == 'quarter'"
                class="quarter">
                <svg
                    height="749"
                    viewBox="0 0 1146 749"
                    width="1146"
                    xmlns="http://www.w3.org/2000/svg">
                    <!-- FUNNEL BG SHAPES -->
                    <path
                        d="m0 0h1146v260h-1146z"
                        fill="#00a2ea"
                        fill-rule="nonzero" />
                    <path
                        d="m1 260h1143l-146.643126 230h-851.057292z"
                        fill="#038ac6" />
                    <path
                        d="m0 0h847v259h-847z"
                        fill="#00a2ea"
                        transform="translate(148 490)" />
                    <!-- METRIC NAMES -->
                    <g
                        fill="#fff"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="48"
                        font-weight="500">
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="90">{{ metrics[keys[0]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="340">{{ metrics[keys[1]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="600">{{ metrics[keys[2]].name.toUpperCase() }}</tspan>
                        </text>
                    </g>
                    <!-- METRIC VALUES -->
                    <g
                        fill="#fff"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="72"
                        font-weight="600">
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="165">{{ metrics[keys[0]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="415">{{ metrics[keys[1]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="560"
                                y="675">{{ metrics[keys[2]].formatted }}</tspan>
                        </text>
                    </g>
                </svg>
            </div>
            <div v-if="size == 'full'">
                <svg
                    height="1750"
                    viewBox="0 0 2139 1750"
                    width="2139"
                    xmlns="http://www.w3.org/2000/svg">
                    <!-- FUNNEL BG SHAPES -->
                    <path
                        d="m366.167666 978-182.738666-206.515-.429.000876v-282.485876h.200068l-183.200068-207v-282h2139v282l-186.69559 207h-.44287l-.00054 282h.49633l-183.88433 207h-3.59912v286h4.16977l-186.82068 208h-.22297v278h-1029v-278h-3.803306l-183.322814-208v-286z"
                        fill="#03a2ea" />
                    <path
                        d="m1769.04365 1264-186.82068 208h-1033.026276l-183.322814-208zm183.31368-493-183.88433 207h-1402.305334l-183.167666-207zm186.64267-489-186.69559 207h-1769.104342l-183.200068-207z"
                        fill="#000"
                        fill-opacity=".20" />
                    <!-- METRIC NAMES -->
                    <g
                        fill="#fff"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="60"
                        font-weight="500">
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="100">{{ metrics[keys[0]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="345">{{ metrics[keys[1]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="580">{{ metrics[keys[2]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="840">{{ metrics[keys[3]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1080">{{ metrics[keys[4]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1340">{{ metrics[keys[5]].name.toUpperCase() }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1580">{{ metrics[keys[6]].name.toUpperCase() }}</tspan>
                        </text>
                    </g>
                    <!-- METRIC VALUES -->
                    <g
                        fill="#fff"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="80"
                        font-weight="600">
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="195">{{ metrics[keys[0]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="435">{{ metrics[keys[1]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="675">{{ metrics[keys[2]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="930">{{ metrics[keys[3]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1175">{{ metrics[keys[4]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1430">{{ metrics[keys[5]].formatted }}</tspan>
                        </text>
                        <text text-anchor="middle">
                            <tspan
                                x="1070"
                                y="1675">{{ metrics[keys[6]].formatted }}</tspan>
                        </text>
                    </g>
                </svg>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Funnel',
    props: {
        metrics: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            error: ''
        };
    },
    computed: {
        keys() {
            return Object.keys(this.metrics);
        }
    },
    watch: {
        metrics() {
            if(this.keys.length < 3 && this.size == 'quarter' || this.keys.length < 7 && this.size == 'full') {
                this.error = 'Widget configured incorrectly.';
            } else {
                this.error = '';
            }
        }
    },
    created() {
        if(this.keys.length < 3 && this.size == 'quarter' || this.keys.length < 7 && this.size == 'full') {
            this.error = 'Widget configured incorrectly.';
        }
    }

};
</script>

<style lang="scss" scoped>
.error-message {
    text-align: center;
    border: 2px solid $carnation;
    padding: 30px 15px;
    color: $carnation;
    font-weight: 700;
}
.quarter {
    svg {
        height: auto;
        width: 100%;
    }
}
.full {
    position: relative;
    svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: auto;
    }
}
</style>